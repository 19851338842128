import React from 'react'
import UnderDev from '../components/UnderDev'

function UnderDevPage() {
  return (
    <div>
        <UnderDev/>
    </div>
  )
}

export default UnderDevPage